/*
 * File: index.ts
 * Project: meki
 * File Created: Monday, 12th April 2021 10:19:37 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Friday, 19th January 2024 11:38:33 am
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useEffect } from 'react';
import { SEOHead } from '@components/seo';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import dynamic from 'next/dynamic';
import nookies from 'nookies';
import { FIREBASE_API_KEY, GRAPHQL_URI } from '@config/environment';
import { MEKI_WORKING_ON } from '@config/environment';
import { signInWithCustomToken } from 'firebase/auth';
import { getFirebaseAuth } from '@services/firebase';
import { getSentry } from '@services/sentry';
import { Console } from '@utils';

interface fetchResponse {
  data: {
    amISignedIn: boolean;
  };
}
interface validateAuthMailResponse {
  data: {
    validateAuthMail: {
      isValid: boolean;
      msg: string;
      token: string;
    };
  };
}

const LandingMain = dynamic(
  () => {
    const promise = import('@screens/home').then((mod) => mod.LandingMain);
    return promise;
  },
  { ssr: true, loading: () => <h1 /> },
);

const WorkingLandingMain = dynamic(
  () => {
    const promise = import('@screens/home/workingLanding').then(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (mod) => mod.WorkingLandingMain,
    );
    return promise;
  },
  { ssr: true, loading: () => <h1 /> },
);

interface IndexProps {
  // received from getServerSideProps
  customToken: string;
}

export default function Index(props: IndexProps) {
  useEffect(() => {
    const checkCustomToken = async () => {
      if (props.customToken) {
        try {
          await signInWithCustomToken(
            getFirebaseAuth(),
            props.customToken,
          ).then(() => {
            window.location.href = '/inicio';
          });
        } catch (error) {
          Console.log({
            msg: "Firebase User couldn't sign in with email link",
          });
          await getSentry().then((Sentry) => {
            Sentry.captureException(error);
          });
        }
      }
    };

    void checkCustomToken();
  }, [props.customToken]);

  const workingTreatment = MEKI_WORKING_ON;
  if (workingTreatment) {
    return (
      <>
        <SEOHead title="Meki · Farmacia a domicilio" />
        <WorkingLandingMain />
      </>
    );
  }
  return (
    <>
      <SEOHead title="Meki · Farmacia a domicilio" />
      <LandingMain />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (
  ctx: GetServerSidePropsContext,
) => {
  try {
    const cookies = nookies.get(ctx);
    // If user is signed in, redirect to /inicio
    if (cookies.token) {
      const refreshedTokenResponse = await fetch(
        `https://identitytoolkit.googleapis.com/v1/token?key=${FIREBASE_API_KEY}`,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: `grant_type=refresh_token&refresh_token=${cookies.token}`,
          method: 'POST',
        },
      );
      const { id_token: authToken } = (await refreshedTokenResponse.json()) as {
        id_token: string;
      };

      const amISignedIn = await fetch(GRAPHQL_URI, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `query {
          amISignedIn
        }`,
        }),
      });
      const { data } = (await amISignedIn.json()) as fetchResponse;
      const destination = `/inicio${
        ctx.query.codigo ? `?codigo=${ctx.query.codigo as string}` : ''
      }`;
      if (data.amISignedIn) {
        return {
          props: { amISignedIn: data.amISignedIn },
          redirect: { destination: destination, permanent: false },
        };
      }
    }

    // If user is not already signed in, check for email link
    const token = ctx.query?.token as string;
    if (token) {
      return await fetch(GRAPHQL_URI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `mutation ValidateAuthMail($params: ValidateAuthMailParams!) {
            validateAuthMail(params: $params) {
              isValid
              msg
              token
            }
          }`,
          variables: {
            params: {
              token,
            },
          },
        }),
      })
        .then(async (validationInformation) => {
          const { data: authMailData } =
            (await validationInformation.json()) as validateAuthMailResponse;
          if (authMailData.validateAuthMail.isValid) {
            return {
              props: {
                customToken: authMailData.validateAuthMail.token,
              },
            };
          } else {
            return {
              props: { amISignedIn: false },
              redirect: {
                destination: '/ingresar',
                permanent: false,
              },
            };
          }
        })
        .catch(() => {
          return {
            props: { amISignedIn: false },
          };
        });
    }
    return { props: {} };
  } catch (e) {
    return { props: {} as never };
  }
};
