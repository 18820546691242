import React from 'react';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import data from '../../siteMetaData.json';
import { useRouter } from 'next/router';
import { BASE_URL } from '@config/environment';

interface SEOImage {
  url: string;
  type: string;
  width: string;
  height: string;
}

type SEOPropTypes = {
  title: string;
  description?: string;
  customImage?: SEOImage;
  canonical?: string;
};

const BASE_IMAGES = [
  {
    url: 'https://d1zphfo9y2yvu9.cloudfront.net/meta/Meki.jpg',
    type: 'image/jpg',
    width: 1200,
    height: 630,
  },
  {
    url: 'https://d1zphfo9y2yvu9.cloudfront.net/meta/1200x630.png',
    type: 'image/png',
    width: 1200,
    height: 630,
  },
  {
    url: 'https://d1zphfo9y2yvu9.cloudfront.net/meta/256x256.jpg',
    type: 'image/jpg',
    width: 256,
    height: 256,
  },
  {
    url: 'https://d1zphfo9y2yvu9.cloudfront.net/meta/256x256.png',
    type: 'image/png',
    width: 256,
    height: 256,
  },
];
export function SEOHead(props: SEOPropTypes) {
  const router = useRouter();
  const { description, title, customImage, canonical } = props;
  const site = data.landing;
  const image = customImage;

  const metaDescription = description ? description : site.description;
  const metaTitle = title || site.title;
  const headData = {
    title: metaTitle,
    description: metaDescription,
    ...(canonical ? { canonical } : {}),
  };
  return (
    <>
      <NextSeo
        {...headData}
        openGraph={{
          title: metaTitle,
          description: metaDescription,
          type: site.type,
          url: `${BASE_URL}${router.asPath}`,
          images: [
            image && {
              url: image.url,
              width: Number(image.width.replace('px', '')),
              height: Number(image.height.replace('px', '')),
              alt: metaTitle,
            },
            ...BASE_IMAGES,
          ].filter(Boolean),
        }}
        additionalMetaTags={[{ name: 'viewport', content: site.scale }]}
      />
      <Head>
        <meta charSet={site.charSet} />
      </Head>
    </>
  );
}
